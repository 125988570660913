<template>
  <section class="context-menu-page" @contextmenu.prevent="showContextMenu">
    <ContextMenu v-if="isShowContextMenu" />
    <div class="context-menu-page__title">
      <h3>Context Menu</h3>
      <h4>(Для использования нажмите ПКМ по области)</h4>
    </div>
    <div class="context-menu-page__description">
      <div>
        Контекстное меню вынесено в LayoutMain. Для его вызова создана функция
        handleContextMenuAction в общем хранилище, которая принимает объект с полями:
        <ul>
          <li><b>itemList</b> - массив пунктов контекстного меню</li>
          <li>
            <b>scrollContainer</b> - необязательный аргумент, являющийся нодой блока, при скролле
            которого контекстное меню будет закрываться
          </li>
        </ul>
        Элементами массива пунктов являются объекты с полями:
        <ul>
          <li><b>isShow</b> - отображать пункт или нет</li>
          <li><b>iconType</b> - иконка</li>
          <li><b>actionText</b> - Текст пункта</li>
          <li><b>action</b> - обработчик нажатия на пункт</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ContextMenu from "@/modules/UiKit/components/ContextMenu/ContextMenu";

export default {
  name: "ContextMenuPage",
  mixins: [],
  props: {},
  components: { ContextMenu },
  data() {
    return {
      actionList: [
        {
          isShow: true,
          iconType: "arrow-next",
          actionText: this.isSubtask ? "Перейти к подазадаче" : "Перейти к задаче",
          action: () => {}
        },
        {
          isShow: this.isSubtask ? false : true,
          iconType: "circle-plus",
          actionText: "Добавить подзадачу",
          action: () => {}
        },
        {
          isShow: true,
          iconType: "folder-check",
          actionText: "Выполнить",
          action: () => {}
        },
        {
          isShow: true,
          iconType: "chain",
          actionText: "Копировать ссылку",
          action: () => {}
        },
        {
          isShow: true,
          iconType: "delete",
          actionText: "Удалить",
          action: () => {}
        }
      ]
    };
  },
  mounted() {},
  methods: {
    ...mapActions(["handleContextMenuAction"]),
    showContextMenu() {
      this.handleContextMenuAction({
        itemList: this.actionList,
        scrollContainer: document.querySelector(".stages-and-tasks__body")
      });
    }
  },
  computed: {
    ...mapState(["isShowContextMenu"])
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.context-menu-page {
  height: 80vh;
  width: 100%;

  &__title {
    width: 100%;
    margin: 0 0 24px;
    color: $text-default;
  }

  &__description {
    line-height: 28px;
    font-size: 14px;
  }
}
</style>
